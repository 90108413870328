import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.VUE_APP_API_URL;

export default {
  get(id, token, isSingleSign) {
    if (isSingleSign) {
      return axios.get(`${API_URL}/api/document/${id}/${token}?isSingleSign=${isSingleSign}`);
    } else {
      return axios.get(`${API_URL}/api/document/${id}/${token}`);
    }
  },
  getSecure(id, token) {
    return axios.get(`${API_URL}/api/document/secure/${id}/${token}`, {
      headers: authHeader(),
    });
  },
  getDocument(id) {
    return axios.get(`${API_URL}/api/document/${id}`);
  },
  getTemplate(id) {
    return axios.get(`${API_URL}/api/document/template/${id}`);
  },
  post(documentId, data) {
    return axios.post(
      `${API_URL}/api/document/createTemplate/${documentId}`,
      {
        data,
      }
    );
  },
  getLogo(shortUrl, token) {
    return axios.get(`${API_URL}/api/document/${shortUrl}/${token}/Logo`)
  },
  updateTemplate(documentId,templateId, data) {
    return axios.put(
      `${API_URL}/api/document/update/${documentId}/${templateId}`,
      {
        data,
      }
    );
  },
  // post(id, token, data) {
  //   return axios.post(`${API_URL}/api/document/template/${id}/${token}`, {
  //     data,
  //   });
  // },
  sign(id, token, isFinished, data) {
    return axios({
      url: `${API_URL}/api/document/${id}/${token}/sign?isFinished=${isFinished}`,
      method: "put",
      data,
      responseType: "blob",
    });
  },
  signSecure(id, token, isFinished, data) {
    return axios({
      url: `${API_URL}/api/document/secure/${id}/${token}/sign?isFinished=${isFinished}`,
      method: "put",
      data,
      responseType: "blob",
      headers: authHeader()
    });
  },
  uploadFile(id, token, documentType, fileDocumentOwner, data) {
    let accessToken = JSON.parse(sessionStorage.getItem('token')) || "";
    return axios({
      url: `${API_URL}/api/File/${id}/${token}/Upload/${documentType}/?documentOwner=${fileDocumentOwner}`,
      method: "post",
      data,
      headers: { 'Content-Type': 'multipart/form-data',
        "x-access-token": accessToken
       },
    })
  },
  deleteFile(shortUrl, token, fileId, fileDocumentOwner) {
    return axios.delete(`${API_URL}/api/File/${shortUrl}/${token}/Delete/${fileId}/?documentOwner=${fileDocumentOwner}`, {
      headers: authHeader(),
    })
  }
};
